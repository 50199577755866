import { Badge, Stack, TextStyle } from '@shopify/polaris';
import { ReactElement, ReactNode, ReactText } from 'react';
import { InitialValueEnv } from '../components/Settings';
import { EmbeddableComponent, FieldType } from '../hooks/useFormConfig';

export type Environment = 'test' | 'production';

interface Config {
    client: { id: string; label: string };
    requiredFields: {
        label: string;
        id: string;
        type: string;
        ref: string;
        test: boolean;
        valueControlRegExp?: RegExp;
        environment?: Environment;
    }[];
    keySpecificRequiredFields: Record<
        string,
        Record<
            string,
            {
                label: string;
                id: string;
                type: string;
                ref: string;
                test: boolean;
                valueControlRegExp?: RegExp;
                environment?: Environment;
            }[]
        >
    >;
    additionalSettings:
        | {
              type: string;
              componentKeys: string[];
              component: {
                  type: FieldType;
                  hasAction: boolean;
                  action: (value: string) => void;
                  hasDisableCondition?: () => void;
              };
              label: string;
              getDescription: (value: string) => EmbeddableComponent;
              getValue?: (shop: string) => string;
              value: string;
          }[]
        | [];
    additionalRequiredFields:
        | {
              label: string;
              helpText?: ((env: InitialValueEnv) => EmbeddableComponent) | EmbeddableComponent;
              id: string;
              type: FieldType;
              ref: string;
              test?: boolean;
              initialValue: ((env: InitialValueEnv) => string) | string | boolean;
              disable?: {
                  // See hooks/useFormConfig.tsx -> RequiredField.disable.valueRef
                  valueRef: '!value' | string;
                  value: boolean;
                  label: string;
              };
              attributes?: {
                  maxLength?: number;
                  showCharacterCount: boolean;
              };
              valueControlRegExp?: RegExp;
              environment?: Environment;
          }[];
    // | [];
}

export interface Option {
    value: string;
    label: string;
}

export const currencyOptions: Option[] = [
    { value: 'SEK', label: 'Sweden (SEK)' },
    { value: 'EUR', label: 'Finland (EUR)' },
    { value: 'NOK', label: 'Norway (NOK)' },
    { value: 'DKK', label: 'Denmark (DKK)' },
];

const multiCurrencyMerchantCredentialsFields = currencyOptions.reduce(
    (acc, option) => {
        return {
            ...acc,
            currency: {
                ...acc.currency,
                [option.value]: [
                    {
                        label: `${option.label} Production Merchant Id`,
                        id: 'merchantKey',
                        type: 'text',
                        ref: 'merchantKey',
                        test: false,
                    },
                    {
                        label: `${option.label} Production Merchant Key`,
                        id: 'merchantSecret',
                        type: 'text',
                        ref: 'merchantSecret',
                        test: false,
                    },
                    {
                        label: `${option.label} Test Merchant Id`,
                        id: 'testMerchantKey',
                        type: 'text',
                        ref: 'testMerchantKey',
                        test: true,
                    },
                    {
                        label: `${option.label} Test Merchant Key`,
                        id: 'testMerchantSecret',
                        type: 'text',
                        ref: 'testMerchantSecret',
                        test: true,
                    },
                ],
            },
        };
    },
    { currency: {} },
);

// SVEA
export const config: Config = {
    client: { id: 'svea', label: 'Svea Checkout' },
    requiredFields: [
        {
            label: 'Production Merchant Id',
            id: 'id',
            type: 'text',
            ref: 'merchantKey',
            test: false,
        },
        {
            label: 'Production Merchant Key',
            id: 'key',
            type: 'text',
            ref: 'merchantSecret',
            test: false,
        },
        // {
        //     label: 'Production Merchant Api Key',
        //     id: 'merchantApiKey',
        //     type: 'text',
        //     ref: 'merchantApiKey',
        //     test: false,
        // },
        {
            label: 'Test Merchant Id',
            id: 'testId',
            type: 'text',
            ref: 'testMerchantKey',
            test: true,
        },
        {
            label: 'Test Merchant Key',
            id: 'testKey',
            type: 'text',
            ref: 'testMerchantSecret',
            test: true,
        },
        // {
        //     label: 'Test Merchant Api Key',
        //     id: 'testMerchantApiKey',
        //     type: 'text',
        //     ref: 'testMerchantApiKey',
        //     test: true,
        // },
    ],
    keySpecificRequiredFields: multiCurrencyMerchantCredentialsFields,
    additionalSettings: [
        // {
        //     type: 'updateUrl',
        //     component: {
        //         type: 'textfield',
        //         hasAction: true,
        //         action: (value: string) => navigator.clipboard.writeText(value),
        //     },
        //     label: 'Update Url',
        //     getDescription: (name: string) => `Save the Update URL in the ${name} dashboard.`,
        //     value:
        //         process.env.REACT_APP_API_HOST +
        //         // eslint-disable-next-line no-template-curly-in-string
        //         '/status?order_reference=${order_reference}&order_key=${order_key}&merchant_name=${merchant_name}',
        // },
        {
            type: 'multiCurrencySupport',
            componentKeys: [],
            component: {
                type: 'toggle-button',
                hasAction: true,
                action: () => null,
            },
            label: 'Multi-currency support',
            getDescription: (name: string) => (
                <Stack>
                    <Badge status={'warning'}>Beta</Badge>
                    <div>This feature isn't yet released and is not possible to be enabled</div>
                </Stack>
            ), // 'Enable this option if you want to support multi-currency payments'
            value: '',
        },
        {
            label: 'Account country and store currency',
            type: 'currency',
            getDescription: () =>
                `Select the country your store operates in. This value must match the Svea account's country. You must also set your store's currency to match this setting`,
            componentKeys: [],
            component: {
                type: 'select',
                hasAction: true,
                action: () => null,
                hasDisableCondition: () => false, // TODO: disable if multi-currency enabled as they are xor
            },
            value: 'SEK',
        },
        {
            label: 'Terms and conditions address',
            type: 'termsUri',
            getDescription: () => `Address (URL) of a webpage with your store's terms and conditions`,
            componentKeys: [],
            component: {
                type: 'url',
                hasAction: true,
                action: () => null,
            },
            value: '',
        },
        {
            type: 'merchantLogo',
            componentKeys: [],
            component: {
                type: 'text',
                hasAction: true,
                action: () => null,
            },
            label: 'Merchant logo',
            getDescription: () => `Save a link to a logo to be displayed on the payment page.`,
            value: '',
        },
        {
            type: 'hideTaxColumn',
            componentKeys: [],
            component: {
                type: 'text',
                hasAction: true,
                action: () => null,
            },
            label: 'Hide tax column',
            getDescription: (name: string) => (
                <TextStyle>
                    If enabled, hides the tax (<i>VAT, MVA, MOMS</i>) column on the payment page
                </TextStyle>
            ),
            value: '',
        },
        {
            type: 'hideAnonymousFlag',
            componentKeys: [],
            component: {
                type: 'text',
                hasAction: true,
                action: () => null,
            },
            label: 'HideAnonymous',
            getDescription: (name: string) => ``,
            value: '',
        },
        {
            type: 'hideNotYouFlag',
            componentKeys: [],
            component: {
                type: 'text',
                hasAction: true,
                action: () => null,
            },
            label: 'HideNotYou',
            getDescription: (name: string) => ``,
            value: '',
        },
        {
            type: 'hideChangeAddressFlag',
            componentKeys: [],
            component: {
                type: 'text',
                hasAction: true,
                action: () => null,
            },
            label: 'HideChangeAddress',
            getDescription: (name: string) => ``,
            value: '',
        },
    ],
    additionalRequiredFields: [
        {
            label: '',
            id: 'currency',
            type: 'select',
            ref: 'currency',
            initialValue: 'SEK',
        },
        {
            label: '',
            id: 'termsUri',
            type: 'url',
            ref: 'termsUri',
            // TODO: figure out best way to obtain the store's host to inject here
            initialValue: (env: InitialValueEnv) => env.shopUrl,
            helpText: (env: InitialValueEnv) => (
                <Stack vertical>
                    <TextStyle>
                        The address must be a valid URL and be accessible by the shopper. This setting will affect the
                        "Terms & policies" link on the Svea Checkout page. Be sure to generate the policies in{' '}
                        <a href={env.shopUrl + '/admin/settings/legal'} target="_new">
                            Shopify Admin
                        </a>{' '}
                        or provide external policies page
                    </TextStyle>
                </Stack>
            ),
        },
        {
            label: 'Multicurrency Support',
            id: 'multiCurrencySupport',
            type: 'toggle-button',
            ref: 'multiCurrencySupport',
            initialValue: false,
            helpText: (
                <Stack vertical>
                    <TextStyle>
                        Multi-currency support enables your store to handle payments in multiple currencies by doing
                        currency conversion for prices, and by passing in the payment for correct Svea account. You must
                        enter valid account credentials for all different markets accounts obtained from Svea if you
                        enable this feature
                    </TextStyle>
                </Stack>
            ),
            // Temporarily disable the UI component. The setting can still be enabled in the Firestore document
            disable: {
                label: '',
                value: true,
                valueRef: '!value',
            },
        },
        {
            label: '',
            id: 'merchantLogo',
            type: 'text',
            ref: 'merchantLogo',
            initialValue: '',
            helpText: (initialValueEnv: InitialValueEnv) => {
                console.log(initialValueEnv);
                return (
                    <>
                        <TextStyle>The logo size should be at least 75px x 125px</TextStyle>
                        {initialValueEnv.merchantLogo ? (
                            <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
                                <img src={initialValueEnv.merchantLogo} alt="Merchant logo" height="75" width="125" />
                            </div>
                        ) : (
                            <></>
                        )}
                    </>
                );
            },
        },
        {
            label: 'HIDE TAX COLUMN',
            id: 'hideTaxColumn',
            type: 'toggle-button',
            ref: 'hideTaxColumn',
            initialValue: false,
        },
        {
            label: 'HideAnonymous',
            id: 'hideAnonymousFlag',
            type: 'toggle-button',
            ref: 'hideAnonymousFlag',
            initialValue: true,
        },
        {
            label: 'HideNotYou',
            id: 'hideNotYouFlag',
            type: 'toggle-button',
            ref: 'hideNotYouFlag',
            initialValue: true,
        },
        {
            label: 'HideChangeAddress',
            id: 'hideChangeAddressFlag',
            type: 'toggle-button',
            ref: 'hideChangeAddressFlag',
            initialValue: true,
        },
    ],
};

type EnvCredential = Record<string, string | undefined>;

const envCredentials: EnvCredential = {
    TEST_MERCHANT_ID: process.env.REACT_APP_TEST_MERCHANT_ID,
    TEST_MERCHANT_KEY: process.env.REACT_APP_TEST_MERCHANT_KEY,
    TEST_MERCHANT_API_KEY: process.env.REACT_APP_TEST_MERCHANT_API_KEY,
};

const CredentialLabels: Record<string, string> = {
    TEST_MERCHANT_ID: 'Test Merchant Id',
    TEST_MERCHANT_KEY: 'Test Merchant Key',
    TEST_MERCHANT_API_KEY: 'Test Merchant Api Key',
};

export const checkEnvForCredentials = (): EnvCredential[] | undefined => {
    const foundCredentials = Object.keys(envCredentials).filter((key) => envCredentials[key] !== undefined);

    if (foundCredentials.length) {
        return foundCredentials.map((cred) => ({
            label: CredentialLabels[cred],
            value: envCredentials[cred],
        }));
    } else {
        return;
    }
};
