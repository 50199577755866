import firebase from 'firebase/app';
import { useEffect, useState } from 'react';

export default function useFirestoreDataListener(initialData = []) {
    const [data, setData] = useState<any[]>(initialData);
    const [queue, setQueue] = useState<any>([]);
    let subscriber: any = null;

    // Data Queue handler
    useEffect(() => {
        if (queue.length) {
            setData([...data, ...queue]);
            setQueue([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queue]);

    const subscribe = (query: firebase.firestore.Query, events = ['added']) => {
        const sub = query.onSnapshot((querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                // TODO: add specific event handlers, remove, modify etc.
                if (events.includes(change.type) && change.type === 'added') {
                    // TODO: Need to prevent duplicate data addition (Mainly issue with Hot reload)
                    setQueue([...queue, { id: change.doc.id, ...change.doc.data() }]);
                }
            });
        });

        subscriber = sub;
    };

    const unsubscribe = () => {
        subscriber && subscriber();
        subscriber = null;
    };

    const addData = (dataToAdd: any[]) => {
        setData([...data, ...dataToAdd]);
    };

    const subscribed = !!subscriber;

    return { data, subscribe, unsubscribe, addData, subscribed };
}
