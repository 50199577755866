import React, { useEffect, useState, useContext, useCallback } from 'react';
import firebase from 'firebase/app';
import { ShopContext } from '../context/ShopContext';
import useFirestoreDataListener from '../hooks/useFirestoreDataListener';
import { getRefunds } from '../api/firestore';
import { Layout, Page } from '@shopify/polaris';
import { FirebaseContext } from '../context/FirebaseContext';
import { Refund, ItemTypes } from '../interfaces';
import List from './List';
import LoadingCard from './LoadingCard';
import ErrorPage from './ErrorPage';

const Refunds = () => {
    const { initialized } = useContext(FirebaseContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [initialData, setInitialData] = useState<Refund[] | []>([]);
    const { data, addData, subscribe, subscribed, unsubscribe } = useFirestoreDataListener([]);
    const { shop } = useContext(ShopContext);

    const fetchRefunds = useCallback(async (shop: string) => {
        const data = await getRefunds(shop);
        setInitialData(data);
        setLoading(false);
    }, []);

    useEffect(() => {
        if (!initialData.length) fetchRefunds(shop.shop);
    }, [fetchRefunds, initialData.length, shop]);

    useEffect(() => {
        if (shop && !loading) {
            addData(initialData);
            let query = firebase
                .firestore()
                .collection(shop.shop)
                .doc('refunds')
                .collection('refunds')
                .where('createdAt', '!=', false);
            if (initialData.length) {
                query = query.where('createdAt', '>', initialData[initialData.length - 1].createdAt);
            }
            query = query.orderBy('createdAt', 'asc');
            if (!subscribed) {
                subscribe(query);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscribed, initialData, shop, loading]);

    useEffect(() => {
        return () => {
            unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscribed]);

    if (!initialized) return <ErrorPage error={{ message: '500', payload: 'Database error' }} />;

    return (
        <Page fullWidth title="Refunds">
            {loading ? (
                <LoadingCard />
            ) : (
                <Layout>
                    <Layout.Section fullWidth>
                        <List
                            items={data}
                            itemType={ItemTypes.REFUND}
                            resourceName={{ singular: 'refund', plural: 'refunds' }}
                            loading={loading}
                        />
                    </Layout.Section>
                </Layout>
            )}
        </Page>
    );
};

export default Refunds;
