import React, { useCallback, useEffect, useState, useContext, FormEvent } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form, FormLayout, Layout, Page, Stack, TextStyle, Card, TextField, Banner } from '@shopify/polaris';
import { getMerchant } from '../api/firestore';
import { getQueryParams, QueryParams } from '../utils';
import { FirebaseContext } from '../context/FirebaseContext';
import {
    useFormConfig,
    FormData,
    useAdditionalFormConfig,
    AdditionalFormData,
    useKeySpecificFormConfig,
    RequiredField,
    MulticurrencySpecificFormData,
    useMulticurrencySpecificFormConfig,
} from '../hooks/useFormConfig';
import { checkEnvForCredentials, config, currencyOptions } from '../config';
import LoadingCard from './LoadingCard';
import Modal, { ModalConfig } from './Modal';

/**
 * Get a URL pointing to the app's handlers. Will fail if REACT_APP_API_HOST env variable isn't set
 */
const apiUrl = (path: string): string => {
    let url = process.env.REACT_APP_API_HOST;
    if (!url) {
        throw new Error('REACT_APP_API_HOST missing');
    }
    return (
        (url.endsWith('/') ? url.substring(0, url.length - 1) : url) +
        '/' +
        (path.startsWith('/') ? path.substring(1) : path)
    );
};

export interface InitialValueEnv {
    shopUrl: string;
    merchantLogo?: string;
}

const Settings = () => {
    const queryParams: QueryParams = getQueryParams();
    const { code, shop } = queryParams;
    const envCredentials = checkEnvForCredentials();
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingSaveConfiguration, setLoadingSaveConfiguration] = useState<boolean>(false);
    const { initialized } = useContext(FirebaseContext);
    const { provider } = useParams<{ provider?: string }>();
    // const [savedMerchant, setSavedMerchant] = useState<FormData>({
    //     id: '',
    //     key: '',
    //     merchantApiKey: '',
    //     testId: '',
    //     testKey: '',
    //     testMerchantApiKey: '',
    // });
    const [formData, setFormData] = useState<FormData>({
        id: '',
        key: '',
        merchantApiKey: '',
        testId: process.env.REACT_APP_TEST_MERCHANT_ID || '',
        testKey: process.env.REACT_APP_TEST_MERCHANT_KEY || '',
        testMerchantApiKey: process.env.REACT_APP_TEST_MERCHANT_API_KEY || '',
        // currency: '',
    });
    const [keySpecificFormData, setKeySpecificFormData] = useState<Record<string, Record<string, FormData>>>({
        currency: currencyOptions.reduce(
            (acc, option) => ({
                ...acc,
                [option.value]: {
                    merchantKey: '',
                    merchantSecret: '',
                    merchantApiKey: '',
                    testMerchantKey: process.env.REACT_APP_TEST_MERCHANT_ID || '',
                    testMerchantSecret: process.env.REACT_APP_TEST_MERCHANT_KEY || '',
                    testMerchantApiKey: process.env.REACT_APP_TEST_MERCHANT_API_KEY || '',
                },
            }),
            {},
        ),
    });
    const [settingsModal, setSettingsModal] = useState<ModalConfig>({
        show: false,
        title: null,
        content: null,
        configurationSavedRedirectUrl: '',
        configurationSaveSuccess: false,
        primaryActionLabel: 'Confirm',
        secondaryActionLabel: 'Cancel',
        onlyPrimaryAction: false,
        isConfirmationModal: false,
        confirmedFormData: false,
    });

    const initialValueEnv = {
        shopUrl: `https://${shop}`,
    } as InitialValueEnv;

    const [additionalFormData, setAdditionalFormData] = useState<AdditionalFormData>(
        config.additionalSettings
            ? config.additionalRequiredFields.reduce(
                  (
                      acc: AdditionalFormData,
                      additionalRequiredField: {
                          id: string;
                          initialValue: ((env: InitialValueEnv) => string) | string | boolean;
                      },
                  ) => {
                      return {
                          ...acc,
                          [additionalRequiredField.id]:
                              typeof additionalRequiredField.initialValue === 'function'
                                  ? additionalRequiredField.initialValue(initialValueEnv)
                                  : additionalRequiredField.initialValue,
                      };
                  },
                  {} as AdditionalFormData,
              )
            : {},
    );

    const currencyRates = currencyOptions.reduce(
        (acc, option) => ({
            ...acc,
            [option.value]: {
                from: option.value,
                rates: currencyOptions.reduce((a, o) => (o.value === option.value ? a : { ...a, [o.value]: '' }), {}),
            },
        }),
        {},
    );
    const [multicurrencySpecificAdditionalFormData, setMulticurrencySpecificAdditionalFormData] =
        useState<MulticurrencySpecificFormData>(currencyRates);

    const [isCopied, setIsCopied] = useState(false);

    const formMarkup = useFormConfig(formData, setFormData);
    const keySpecificformMarkup = useKeySpecificFormConfig(keySpecificFormData, setKeySpecificFormData);
    const multicurrencySpecificFormMarkup = useMulticurrencySpecificFormConfig(
        multicurrencySpecificAdditionalFormData,
        setMulticurrencySpecificAdditionalFormData,
    );

    const { additionalFormMarkup } = useAdditionalFormConfig(
        additionalFormData,
        setAdditionalFormData,
        initialValueEnv,
    );

    const getStore = () => {
        const store = shop;
        if (!store) {
            console.error('Value for the shop query param not found from query parameters', store, queryParams);
            throw new Error('Store domain not found');
        }
        return store;
    };

    const fetchMerchant = useCallback(async () => {
        const store = getStore();
        const data = await getMerchant(store, provider);
        // setSavedMerchant({
        //     id: data.merchantKey || '',
        //     key: data.merchantSecret || '',
        //     merchantApiKey: data.merchantApiKey || '',
        //     testId: data.testMerchantKey || '',
        //     testKey: data.testMerchantSecret || '',
        //     testMerchantApiKey: data.testMerchantApiKey || '',
        // });
        setFormData({
            id: data.merchantKey || '',
            key: data.merchantSecret || '',
            merchantApiKey: data.merchantApiKey || '',
            testId: data.testMerchantKey || '',
            testKey: data.testMerchantSecret || '',
            testMerchantApiKey: data.testMerchantApiKey || '',
            // currency: (data.merchantSettings?.currency as string) || '',
            // termsUri: (data.merchantSettings?.termsUri as string) || '',
        });
        if (data.keys) {
            setKeySpecificFormData(data.keys as any);
        }
        if (data.merchantSettings) {
            const { rates, ...merchantSettings } = data.merchantSettings;
            if (rates)
                setMulticurrencySpecificAdditionalFormData({
                    ...multicurrencySpecificAdditionalFormData,
                    ...(rates as MulticurrencySpecificFormData),
                });

            setAdditionalFormData({ ...additionalFormData, ...merchantSettings });
        }
        return data;
    }, [provider]);

    useEffect(() => {
        try {
            if (initialized) fetchMerchant();
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }, [initialized, fetchMerchant]);

    useEffect(() => {
        if (isCopied) {
            setTimeout(() => setIsCopied(false), 3000);
        }
    }, [isCopied]);

    const validateMulticurrencySpecificAdditionalFormData = (formData: MulticurrencySpecificFormData) => {
        const rates = Object.entries(formData).reduce((acc: MulticurrencySpecificFormData, [currency, values]) => {
            const currencyRates = Object.entries(values.rates).reduce(
                (acc: Record<string, number>, [to, rate]) =>
                    !parseFloat(rate as string) ? acc : { ...acc, [to]: parseFloat(rate as string) },
                {},
            );

            if (Object.values(currencyRates).length)
                return { ...acc, [currency]: { ...formData[currency], date: Date.now(), rates: currencyRates } };

            return acc;
        }, {});
        if (!Object.keys(rates).length) return {};

        return { rates };
    };

    const notifyReadiness = async (
        shop: string,
        formData: FormData,
        keySpecificFormData: Record<string, Record<string, FormData>>,
        additionalFormData: AdditionalFormData,
        multicurrencySpecificAdditionalFormData: MulticurrencySpecificFormData,
        providerId?: string,
    ): Promise<{
        credentialsValid: boolean;
        testCredentialsValid: boolean;
        keys: Record<string, Record<string, { credentialsValid: boolean; testCredentialsValid: boolean }>>;
        redirectUrl: string | null;
    }> => {
        const validatedMulticurrencySpecificAdditionalFormData = validateMulticurrencySpecificAdditionalFormData(
            multicurrencySpecificAdditionalFormData,
        );

        const url = apiUrl(`/configure`);
        const payload = {
            shop: shop,
            merchantKey: formData.id,
            merchantSecret: formData.key,
            merchantApiKey: formData.merchantApiKey,
            testMerchantKey: formData.testId,
            testMerchantSecret: formData.testKey,
            testMerchantApiKey: formData.testMerchantApiKey,
            provider: providerId,
            keys: keySpecificFormData,
            merchantSettings: { ...additionalFormData, ...validatedMulticurrencySpecificAdditionalFormData },
        };

        console.log('PAYLOAD: ', payload);
        const response = await fetch(url, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            const invalidCredentials = response.status === 402;
            console.error(
                `HTTP call trying to notify the app of merchant readiness failed${
                    invalidCredentials ? ' because of Invalid credentials' : ''
                }`,
                response,
            );
            throw new Error(
                invalidCredentials
                    ? 'Invalid credentials given. Check the credentials'
                    : 'Could not configure the merchant information',
            );
        }

        const data = await response.json();

        return data;
    };

    const checkProvidedCredentials = (formData: FormData, test: boolean, requiredFields: RequiredField[]): boolean => {
        return requiredFields
            .filter((requiredField) => (test ? requiredField.test : !requiredField.test))
            .some((requiredField) => formData[requiredField.id]);
    };

    const checkFormData = async (formData: FormData) => {
        const providedCredentials = checkProvidedCredentials(formData, false, config.requiredFields);
        const providedTestCredentials = checkProvidedCredentials(formData, true, config.requiredFields);

        if (providedCredentials && providedTestCredentials) {
            setSettingsModal({ ...settingsModal, confirmedFormData: true });
            return true;
        } else {
            setSettingsModal({
                show: true,
                title: <TextStyle>Confirm Saving Credentials</TextStyle>,
                content: (
                    <Stack vertical>
                        <TextStyle>
                            You are about to save without providing any{' '}
                            <TextStyle variation="strong">
                                {(!providedCredentials && 'Production') || (!providedTestCredentials && 'Test')}
                            </TextStyle>{' '}
                            credentials.
                        </TextStyle>

                        <Banner
                            title={`This action will remove any previously saved ${
                                (!providedCredentials && 'Production') || (!providedTestCredentials && 'Test')
                            } credentials`}
                            status="critical"
                        >
                            <TextStyle>
                                The same credentials are shared with all{' '}
                                <TextStyle variation="strong">{config.client.label}</TextStyle> payment applications in
                                this store and customers will not be able to complete payments in{' '}
                                <TextStyle variation="strong">
                                    {(!providedCredentials && 'Production') || (!providedTestCredentials && 'Test')}{' '}
                                    mode
                                </TextStyle>{' '}
                                if they are removed.
                            </TextStyle>
                        </Banner>
                        <TextStyle>
                            Please <TextStyle variation="strong">Confirm</TextStyle> to proceed or{' '}
                            <TextStyle variation="strong">Cancel</TextStyle> to edit.
                        </TextStyle>
                    </Stack>
                ),
                primaryActionLabel: 'Confirm',
                secondaryActionLabel: 'Cancel',
                onlyPrimaryAction: false,
                isConfirmationModal: true,
                confirmedFormData: false,
            });

            return settingsModal.confirmedFormData;
        }
    };

    const handleSubmit = useCallback(
        async (e: FormEvent) => {
            e.preventDefault();
            await checkFormData(formData);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formData, provider],
    );

    const handleNotifyReadiness = useCallback(
        async (
            formData: FormData,
            keySpecificFormData: Record<string, Record<string, FormData>>,
            additionalFormData: AdditionalFormData,
            multicurrencySpecificAdditionalFormData: MulticurrencySpecificFormData,
        ) => {
            setLoadingSaveConfiguration(true);
            try {
                const store = getStore();
                const notifyReadinessResponse = await notifyReadiness(
                    store,
                    formData,
                    keySpecificFormData,
                    additionalFormData,
                    multicurrencySpecificAdditionalFormData,
                    provider,
                );
                const providedCredentials = checkProvidedCredentials(formData, false, config.requiredFields);
                const providedTestCredentials = checkProvidedCredentials(formData, true, config.requiredFields);
                const keySpecificCredentialsResponses = Object.entries(notifyReadinessResponse.keys).reduce(
                    (
                        acc: { key: string; credentialsValid: boolean; testCredentialsValid: boolean }[],
                        [_key, resultsByKey],
                    ) => {
                        const keyResults = Object.entries(resultsByKey).reduce(
                            (
                                acc: { key: string; credentialsValid: boolean; testCredentialsValid: boolean }[],
                                [resultKey, result],
                            ) => {
                                return [...acc, { key: resultKey, ...result }];
                            },
                            [],
                        );

                        return [...acc, ...keyResults];
                    },
                    [],
                );

                const checkedKeySpecificCredentials = keySpecificCredentialsResponses.map((keyResponse) => ({
                    ...keyResponse,
                    providedCredentials: checkProvidedCredentials(
                        keySpecificFormData.currency[keyResponse.key],
                        false,
                        config.keySpecificRequiredFields['currency'][keyResponse.key],
                    ),
                    providedTestCredentials: checkProvidedCredentials(
                        keySpecificFormData.currency[keyResponse.key],
                        true,
                        config.keySpecificRequiredFields['currency'][keyResponse.key],
                    ),
                }));

                setSettingsModal({
                    show: true,
                    title:
                        notifyReadinessResponse.credentialsValid && notifyReadinessResponse.testCredentialsValid ? (
                            <TextStyle variation="positive">Credentials Saved Successfully</TextStyle>
                        ) : notifyReadinessResponse.credentialsValid ? (
                            <TextStyle variation="positive">Production Credentials Saved Successfully</TextStyle>
                        ) : (
                            <TextStyle variation="positive">Test Credentials Saved Successfully</TextStyle>
                        ),
                    content: (
                        <Stack vertical>
                            {notifyReadinessResponse.credentialsValid ? (
                                <TextStyle variation="positive">
                                    Production credentials were {!providedCredentials ? 'cleared' : 'valid'}.
                                </TextStyle>
                            ) : (
                                <TextStyle variation="negative">Production credentials were invalid.</TextStyle>
                            )}
                            {notifyReadinessResponse.testCredentialsValid ? (
                                <TextStyle variation="positive">
                                    Test credentials were {!providedTestCredentials ? 'cleared' : 'valid'}.
                                </TextStyle>
                            ) : (
                                <TextStyle variation="negative">Test credentials were invalid.</TextStyle>
                            )}
                            {additionalFormData?.multiCurrencySupport &&
                                checkedKeySpecificCredentials.map((checkedKey) => {
                                    return (
                                        <Stack vertical>
                                            <TextStyle
                                                variation={checkedKey.credentialsValid ? 'positive' : 'negative'}
                                            >
                                                {checkedKey.key} production credentials were{' '}
                                                {checkedKey.credentialsValid
                                                    ? keySpecificFormData.currency[checkedKey.key].id &&
                                                      !checkedKey.providedCredentials
                                                        ? 'cleared'
                                                        : 'valid'
                                                    : 'invalid'}
                                            </TextStyle>
                                            <TextStyle
                                                variation={checkedKey.testCredentialsValid ? 'positive' : 'negative'}
                                            >
                                                {checkedKey.key} test credentials were{' '}
                                                {checkedKey.testCredentialsValid
                                                    ? keySpecificFormData.currency[checkedKey.key].id &&
                                                      !checkedKey.providedTestCredentials
                                                        ? 'cleared'
                                                        : 'valid'
                                                    : 'invalid'}
                                            </TextStyle>
                                        </Stack>
                                    );
                                })}
                            {notifyReadinessResponse.testCredentialsValid &&
                                !notifyReadinessResponse.credentialsValid && (
                                    <TextStyle>
                                        You can proceed to activate the payment application, but it will only work in
                                        test mode.
                                    </TextStyle>
                                )}
                            {!notifyReadinessResponse.testCredentialsValid &&
                                notifyReadinessResponse.credentialsValid && (
                                    <TextStyle>
                                        You can proceed to activate the payment application, but it will only work in
                                        production mode.
                                    </TextStyle>
                                )}
                            <TextStyle>
                                <TextStyle variation="strong">Confirm</TextStyle> to proceed to Shopify to activate the
                                payment application.
                            </TextStyle>
                            {!code && (
                                <TextStyle>
                                    <TextStyle variation="strong">Cancel</TextStyle> to stay on this page.
                                </TextStyle>
                            )}
                        </Stack>
                    ),
                    configurationSavedRedirectUrl: notifyReadinessResponse.redirectUrl!,
                    configurationSaveSuccess: true,
                    primaryActionLabel: 'Confirm',
                    secondaryActionLabel: 'Cancel',
                    onlyPrimaryAction: !!code,
                    isConfirmationModal: false,
                });
            } catch (e) {
                setSettingsModal({
                    show: true,
                    title: <TextStyle variation="negative">Saving Credentials Failed</TextStyle>,
                    content: ~(e as Error).message.indexOf('Invalid credentials') ? (
                        <Stack vertical>
                            <TextStyle variation="negative">Production credentials were invalid.</TextStyle>
                            <TextStyle variation="negative">Test credentials were invalid.</TextStyle>
                            <TextStyle>Please check your credentials and try again.</TextStyle>
                        </Stack>
                    ) : (
                        <Stack vertical>
                            <TextStyle>
                                Please check all required settings are filled and all fields are filled with allowed
                                values
                            </TextStyle>
                        </Stack>
                    ),
                    configurationSavedRedirectUrl: '',
                    configurationSaveSuccess: false,
                    primaryActionLabel: 'Confirm',
                    secondaryActionLabel: 'Cancel',
                    onlyPrimaryAction: false,
                    isConfirmationModal: false,
                });
                console.error('Error occurred when trying to submit the merchant config form', e);
            } finally {
                setLoadingSaveConfiguration(false);
            }
        },
        [code, getStore, notifyReadiness, provider],
    );

    useEffect(() => {
        if (settingsModal.confirmedFormData) {
            handleNotifyReadiness(
                formData,
                keySpecificFormData,
                additionalFormData,
                multicurrencySpecificAdditionalFormData,
            );
        } else {
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingsModal]);

    // const hasValidData = () => !config.requiredFields.find((requiredField) => !formData[requiredField.id].length);
    // const hasChangedInput = () => !!Object.keys(formData).find((key) => formData[key] !== savedMerchant[key]);
    const hasValidData = config.requiredFields.some((requiredField) => formData[requiredField.id].length);

    const beforeCredentialsAdditionalSettings = additionalFormMarkup.filter(
        (field) => field.key === 'multiCurrencySupport',
    );

    const afterCredentialsAdditionalSettings = additionalFormMarkup.filter(
        (field) => field.key !== 'multiCurrencySupport',
    );

    const { beforeCredentialsAdditionalSettingsMarkUp, afterCredentialsAdditionalSettingsMarkUp } =
        config.additionalSettings &&
        (config.additionalSettings as any).reduce(
            (acc: any, additionalSetting: any) => {
                if (additionalSetting.type === 'multiCurrencySupport')
                    return {
                        ...acc,
                        beforeCredentialsAdditionalSettingsMarkUp: [
                            ...acc.beforeCredentialsAdditionalSettingsMarkUp,
                            <Layout.AnnotatedSection
                                key={additionalSetting.type}
                                title={additionalSetting.label}
                                description={additionalSetting.getDescription(config.client.label)}
                            >
                                <Layout.Section>
                                    {additionalSetting.component.type === 'textfield' && (
                                        <Card sectioned>
                                            <TextField
                                                label={additionalSetting.label}
                                                type="text"
                                                readOnly
                                                value={additionalSetting.value}
                                                onChange={() => null}
                                                autoComplete="off"
                                                // multiline
                                                connectedRight={
                                                    additionalSetting.component.hasAction ? (
                                                        <Button
                                                            onClick={() => {
                                                                additionalSetting.component.action(
                                                                    additionalSetting.value,
                                                                );
                                                                setIsCopied(true);
                                                            }}
                                                        >
                                                            {isCopied ? 'Copied' : 'Copy'}
                                                        </Button>
                                                    ) : (
                                                        ''
                                                    )
                                                }
                                            />
                                        </Card>
                                    )}
                                    {beforeCredentialsAdditionalSettings.find(
                                        (field) => field.key === additionalSetting.type,
                                    )}
                                    {beforeCredentialsAdditionalSettings.filter((field) =>
                                        additionalSetting.componentKeys.includes(field.key as string),
                                    ).length
                                        ? beforeCredentialsAdditionalSettings.filter((field) =>
                                              additionalSetting.componentKeys.includes(field.key as string),
                                          )
                                        : ''}
                                </Layout.Section>
                            </Layout.AnnotatedSection>,
                        ],
                    };

                if (additionalSetting.type !== 'multiCurrencySupport')
                    return {
                        ...acc,
                        afterCredentialsAdditionalSettingsMarkUp: [
                            ...acc.afterCredentialsAdditionalSettingsMarkUp,
                            <Layout.AnnotatedSection
                                key={additionalSetting.type}
                                title={additionalSetting.label}
                                description={additionalSetting.getDescription(config.client.label)}
                            >
                                <Layout.Section>
                                    {additionalSetting.component.type === 'textfield' && (
                                        <Card sectioned>
                                            <TextField
                                                label={additionalSetting.label}
                                                type="text"
                                                readOnly
                                                value={additionalSetting.value}
                                                onChange={() => null}
                                                autoComplete="off"
                                                // multiline
                                                connectedRight={
                                                    additionalSetting.component.hasAction ? (
                                                        <Button
                                                            onClick={() => {
                                                                additionalSetting.component.action(
                                                                    additionalSetting.value,
                                                                );
                                                                setIsCopied(true);
                                                            }}
                                                        >
                                                            {isCopied ? 'Copied' : 'Copy'}
                                                        </Button>
                                                    ) : (
                                                        ''
                                                    )
                                                }
                                            />
                                        </Card>
                                    )}
                                    {afterCredentialsAdditionalSettings.find(
                                        (field) => field.key === additionalSetting.type,
                                    )}
                                    {afterCredentialsAdditionalSettings.filter((field) =>
                                        additionalSetting.componentKeys.includes(field.key as string),
                                    ).length
                                        ? afterCredentialsAdditionalSettings.filter((field) =>
                                              additionalSetting.componentKeys.includes(field.key as string),
                                          )
                                        : ''}
                                </Layout.Section>
                            </Layout.AnnotatedSection>,
                        ],
                    };

                return acc;
            },
            { beforeCredentialsAdditionalSettingsMarkUp: [], afterCredentialsAdditionalSettingsMarkUp: [] },
        );

    return (
        <Page fullWidth title="Settings">
            {loading ? (
                <LoadingCard />
            ) : (
                <Layout>
                    {beforeCredentialsAdditionalSettingsMarkUp}
                    <Layout.AnnotatedSection
                        title="Merchant Credentials"
                        description={
                            <Stack vertical>
                                <TextStyle>These credentials are used to process payments.</TextStyle>
                                {envCredentials && (
                                    <Stack vertical spacing="tight">
                                        <TextStyle variation="strong">Available default test credentials</TextStyle>
                                        {envCredentials.map((cred) => (
                                            <Stack key={cred.label} vertical spacing="none">
                                                <TextStyle variation="subdued">{cred.label}</TextStyle>
                                                <TextStyle variation="code">{cred.value}</TextStyle>
                                            </Stack>
                                        ))}
                                    </Stack>
                                )}
                            </Stack>
                        }
                    >
                        <Layout.Section>
                            <Card sectioned>
                                <Form onSubmit={handleSubmit}>
                                    <FormLayout>
                                        {additionalFormData?.multiCurrencySupport
                                            ? formMarkup.map((element) => ({
                                                  ...element,
                                                  props: { ...element.props, label: `Global ${element.props.label}` },
                                              }))
                                            : formMarkup}
                                        {additionalFormData?.multiCurrencySupport && keySpecificformMarkup}
                                        <Button
                                            loading={loadingSaveConfiguration}
                                            submit
                                            primary
                                            size="large"
                                            disabled={!hasValidData /*|| !hasChangedInput()*/}
                                        >
                                            Save
                                        </Button>
                                        <Modal modal={settingsModal} setModal={setSettingsModal} />
                                    </FormLayout>
                                </Form>
                            </Card>
                        </Layout.Section>
                    </Layout.AnnotatedSection>
                    {additionalFormData?.multiCurrencySupport && (
                        <Layout.Section>
                            <Layout.AnnotatedSection
                                key={'multicurrencySpecificSettings'}
                                title={'Static Multicurrency Conversion Rates'}
                                description={
                                    'Set static conversion rates to be used for currency conversions. Note that you need to define all rates for a specific currency to ensure correct functionality.'
                                }
                            >
                                {multicurrencySpecificFormMarkup}
                            </Layout.AnnotatedSection>
                        </Layout.Section>
                    )}
                    {afterCredentialsAdditionalSettingsMarkUp}
                </Layout>
            )}
        </Page>
    );
};

export default Settings;
