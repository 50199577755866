import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Settings from '../components/Settings';
import Install from '../components/Install';
import Payments from '../components/Payments';
import Refunds from '../components/Refunds';

const Router = () => {
    return (
        <Routes>
            <Route path="/:provider?" element={<Settings />} />
            <Route path="/install/:provider?" element={<Install />} />
            <Route path="/payments/:provider?" element={<Payments />} />
            <Route path="/refunds/:provider?" element={<Refunds />} />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );
};

export default Router;
