import React from 'react';
import { Card, SkeletonBodyText } from '@shopify/polaris';

const LoadingCard = () => {
    return (
        <Card>
            <Card.Section>
                <SkeletonBodyText />
            </Card.Section>
        </Card>
    );
};

export default LoadingCard;
