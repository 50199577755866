import { QueryParams, checkResponseStatus } from '../utils';

export const authenticate = async (payload: QueryParams) => {
    const url = `${process.env.REACT_APP_API_HOST}/auth`;
    const res = await fetch(url, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    await checkResponseStatus(res);

    const data = await res.json();
    return data;
};

export const install = async (payload: QueryParams) => {
    const url = `${process.env.REACT_APP_API_HOST}/install`;
    const res = await fetch(url, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    if (res.status === 200) {
        const data = await res.json();
        return data;
    }
};
