import React, { createContext, useState, useEffect, ReactElement } from 'react';
import { Shop, Payment, Refund } from '../interfaces/index';

type ShopProviderType = {
    children: ReactElement;
    shopUrl: string;
};

type ShopContextType = {
    shop: Shop;
    addShop: (newShop: any) => void;
    payments: Payment[] | [];
    addPayments: (newPayment: Payment) => void;
    refunds: Refund[] | [];
    addRefunds: (newRefund: Refund) => void;
};

const contextDefaultValues: ShopContextType = {
    shop: { shop: '' },
    addShop: () => {},
    payments: [],
    addPayments: () => {},
    refunds: [],
    addRefunds: () => {},
};

export const ShopContext = createContext<ShopContextType>(contextDefaultValues);

const ShopProvider = ({ children, shopUrl }: ShopProviderType) => {
    const [shop, setShop] = useState<Shop>(contextDefaultValues.shop);
    const [payments, setPayments] = useState<Payment[] | []>(contextDefaultValues.payments);
    const [refunds, setRefunds] = useState<Refund[] | []>(contextDefaultValues.refunds);

    const addShop = (newShop: Shop) => setShop(newShop);
    const addPayments = (newPayment: Payment) => setPayments([...payments, newPayment]);
    const addRefunds = (newRefund: Refund) => setRefunds([...refunds, newRefund]);

    useEffect(() => {
        if (shopUrl) setShop({ shop: shopUrl });
    }, [shopUrl]);

    return (
        <ShopContext.Provider
            value={{
                shop,
                addShop,
                payments,
                addPayments,
                refunds,
                addRefunds,
            }}
        >
            {children}
        </ShopContext.Provider>
    );
};

export default ShopProvider;
