import React /*, { useContext }*/ from 'react';
import { Page, Layout, EmptyState, Card } from '@shopify/polaris';
import { ApiError } from '../utils';
// import { ShopContext } from '../context/ShopContext';

interface ErrorPageProps {
    error: ApiError | any;
}

const ErrorPage = ({ error }: ErrorPageProps) => {
    // const { shop } = useContext(ShopContext);

    return (
        <Page fullWidth>
            <Layout>
                <Layout.Section>
                    <Card sectioned>
                        <EmptyState
                            heading={`Server responded with status ${error.message} ${error.payload}`}
                            // action={{
                            //     content: 'Try relaunching the app from shopify',
                            //     onAction: () =>
                            //         (window.location.href = `https://${shop.shop}/admin/settings/payments/alternative-providers`),
                            // }}
                            // image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                            image=""
                            fullWidth
                        >
                            <p>
                                Something went wrong with loading the app. Please try relaunching the app from Shopify.
                            </p>
                        </EmptyState>
                    </Card>
                </Layout.Section>
            </Layout>
        </Page>
    );
};

export default ErrorPage;
